'use client'

import {
	defaultCountry,
	defaultLanguage,
	regexHaveCountryAndLanguage,
} from 'country-configuration/constants'
import { useClientLabels } from 'labels/hooks/useClientLabels/useClientLabels'
import { errorKeys } from 'monitoring/keys'
import { CUSTOM_ERROR_KEYS } from 'monitoring/utils/dynatraceKeys'
import { useEffect } from 'react'

// Can't use css, nextjs bug on global-error.tsx
// https://github.com/vercel/next.js/issues/46964
import { styles } from './ErrorComponent.styles'

// Can't use useState, reset() erase everything
let retries = 1

const MAX_RETRIES = 3
const jsSrc = process.env.NEXT_PUBLIC_DYNATRACE_JS

export const ErrorComponent = ({
	error,
	reset,
}: {
	readonly error: Error & { digest?: string }
	readonly reset: () => void
}) => {
	let countryISO
	let languageISO
	const { pathname } = window.location
	if (regexHaveCountryAndLanguage.test(pathname)) {
		;[countryISO, languageISO] = pathname.split('/').slice(1)
	} else {
		countryISO = defaultCountry
		languageISO = defaultLanguage
	}
	const { t } = useClientLabels({
		keys: errorKeys,
		countryISO,
		languageISO,
	})

	useEffect(() => {
		if (error && retries === 1 && jsSrc) {
			const script = document.createElement('script')
			script.src = jsSrc
			script.async = true
			script.crossOrigin = 'anonymous'
			script.onload = () => {
				window.dtrum.enable()
				window.dtrum.reportCustomError(
					CUSTOM_ERROR_KEYS.GLOBAL_ERROR,
					'The page is not available at this moment',
					JSON.stringify({ error })
				)
			}

			document.body.appendChild(script)
		}
	}, [error])

	const handleReset = () => {
		retries = retries + 1
		reset()
	}

	const handleDiscover = () => {
		retries = 1
		window.location.assign('/')
	}

	return (
		<div style={styles.container}>
			{retries > MAX_RETRIES ? (
				<>
					<span style={styles.title}>{t('error.notAvailable.title')}</span>
					<span>{t('error.notAvailable.bodycopy')}</span>
					<button type='button' onClick={handleDiscover} style={styles.button}>
						{t('error404.discover.button')}
					</button>
				</>
			) : (
				<>
					<span style={styles.title}>{t('error.display.title')}</span>
					<span>{t('error.display.bodycopy')}</span>
					<button type='button' onClick={handleReset} style={styles.button}>
						{t('error.retry.button')}
					</button>
				</>
			)}
		</div>
	)
}
